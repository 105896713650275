// eslint-disable-next-line
import { useLocation } from '@reach/router';

export default function useLocationData() {
    const location = useLocation();
    const url = location.pathname.slice(1, -1).split('/');

    const replace = (text: string) => text.replaceAll('-', ' ').replaceAll('and', '&');

    const category = url.length >= 2 ? replace(url[0]) : '';
    let subCategory = url.length === 3 ? replace(url[1]) : '';
    const handle =
        url.length === 1 ? url[0] : url.length === 2 ? url[1] : url.length === 3 ? url[2] : '';
    let title =
        url.length === 1
            ? replace(url[0])
            : url.length === 2
            ? replace(url[1])
            : url.length === 3
            ? replace(url[2])
            : '';
    title = title === 'photo gallery' ? 'Our Beauty Transformations' : title;
    title = title === 'scarlet srf micro needling' ? 'scarlet SRF micro-needling' : title;
    title = title === 'agnes rf micro needling' ? 'agnes RF micro-needling' : title;
    title = title === 'nad' ? 'NAD+' : title;
    title = title === 'mayers cocktail' ? 'mayer’s cocktail' : title;
    title = title === 'iv therapy' ? 'IV therapy' : title;
    title = title === 'qwo injections for cellulite' ? 'QWO injections for cellulite' : title;
    title = title === 'pdo thread lift' ? 'PDO thread lift' : title;
    title = title === 'ipl photofacial' ? 'IPL photofacial' : title;
    title = title === 'prx derm perfexion' ? 'PRX Derm Perfexion' : title;
    title = title === 'prf & prp prf gel naturelle' ? 'PRF & PRP, PRF Gel Naturelle' : title;
    title =
        title === 'juvederm voluma restylane kysse & versa'
            ? 'Juvederm Voluma, Restylane Kysse & Versa'
            : title;
    title = title === 'mesotherapy bio revitalization' ? 'Mesotherapy / Bio Revitalization' : title;
    title = title === 'bodysculp fat reduction' ? 'bodySculp fat reduction' : title;
    title =
        title === 'sylfirmx rf microneedling skin tightening'
            ? 'SylfirmX RF Microneedling Skin Tightening'
            : title;
    title = title === 'cryo skin therapy' ? 'CRYO skin therapy' : title;

    subCategory = subCategory === 'iv therapy' ? 'IV therapy' : subCategory;

    return { category, subCategory, title, handle };
}
