import { css, Global, Theme } from '@emotion/react';
import '@fontsource/alex-brush';
import '@fontsource/cormorant/500.css';
import '@fontsource/manrope';
import '@fontsource/manrope/700.css';
import '@fontsource/manrope/800.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4, h5, h6 } from './Typography';

export const theme: Theme = {
    colors: {
        gray1: '#FFFFFF',
        gray2: '#8D9EA5',
        gray3: '#161717',
        gray4: '#8d9ea54d',
        yellow1: '#DAB04B',
        yellow2: '#746A52',
        green1: '#9FC5A6',
        green2: '#6D8945',
        green3: '#2C362A',
        pink1: '#E46D76',
        pink2: '#A24841',
        pink3: '#EEE1E1',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={theme => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.gray3};
                    font-family: 'Manrope', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 168%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                    color: ${theme.colors.green2};
                }

                h3 {
                    ${h3};
                    font-weight: 400;
                }

                h4 {
                    ${h4};
                }

                h5 {
                    ${h5};
                    color: ${theme.colors.pink1};
                }

                h6 {
                    ${h6};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.gray3};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                // for animations on homepage

                .reveal {
                    transform: translateY(70px);
                    opacity: 0;
                    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
                }

                .reveal-slide-left {
                    transform: translateX(-70px);
                    opacity: 0;
                    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
                }

                .revealed {
                    opacity: 1;
                    transform: translate(0);
                }

                .reveal-delay-mobile-1 {
                    transition-delay: 0.1s;
                }

                .reveal-delay-mobile-2 {
                    transition-delay: 0.2s;
                }

                .reveal-delay-mobile-3 {
                    transition-delay: 0.3s;
                }

                .reveal-delay-mobile-4 {
                    transition-delay: 0.4s;
                }

                .reveal-delay-mobile-5 {
                    transition-delay: 0.5s;
                }

                .reveal-delay-mobile-6 {
                    transition-delay: 0.6s;
                }

                .reveal-delay-mobile-7 {
                    transition-delay: 0.7s;
                }

                .reveal-delay-mobile-8 {
                    transition-delay: 0.8s;
                }

                .reveal-delay-mobile-9 {
                    transition-delay: 0.9s;
                }

                @media (min-width: 1024px) {
                    .reveal-delay-1 {
                        transition-delay: 0.1s;
                    }

                    .reveal-delay-2 {
                        transition-delay: 0.2s;
                    }

                    .reveal-delay-3 {
                        transition-delay: 0.3s;
                    }

                    .reveal-delay-4 {
                        transition-delay: 0.4s;
                    }

                    .reveal-delay-5 {
                        transition-delay: 0.5s;
                    }
                }
            `}
        />
    );
};
