import { PlainBtn } from '@/buttons';
import { ContactUs } from '@/layout/ContactUs';
import { Footer } from '@/layout/Footer';
import { Guest } from '@/layout/Guest';
import { HoursMap } from '@/layout/HoursMap';
import { Nav } from '@/layout/Nav';
import { Seo } from '@/layout/Seo';
import { GlobalStyles, theme } from '@/layout/styles/GlobalStyles';
import { ReactComponent as ScrollIcon } from '@a/images/layout/scroll-top.svg';
import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import useEventListener from '@s/hooks/useEventListener';
import { Script } from 'gatsby';
import { ReactNode, useState } from 'react';

const ScrollBtn = styled(PlainBtn)<{ scrolled: boolean }>`
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: ${({ scrolled }) => (scrolled ? 'flex' : 'none')};
    z-index: 1000;

    > svg path {
        transition: fill 0.3s ease-in-out;
    }

    :hover {
        > svg path {
            fill: ${({ theme }) => theme.colors.green3};
        }
    }

    @media (min-width: 768px) {
        bottom: 40px;
        right: 40px;
    }
`;

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
    const [scrolled, setScrolled] = useState(false);

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const onScroll = () => {
        if (window.scrollY > window.innerHeight) setScrolled(true);
        else setScrolled(false);
    };

    useEventListener('scroll', onScroll);

    return (
        <ThemeProvider theme={theme}>
            <ScrollBtn onClick={handleClick} scrolled={scrolled}>
                <ScrollIcon />
            </ScrollBtn>
            <GlobalStyles />
            <Seo />
            <Nav />
            <main>{children}</main>
            <Guest />
            <HoursMap />
            <ContactUs />
            <Footer />
            <chat-widget
                style={{
                    '--chat-widget-primary-color': '#E46D77',
                    '--chat-widget-active-color': '#E46d77',
                    '--chat-widget-bubble-color': '#E46D77',
                }}
                support-contact="+1 (310) 299-6416"
                location-id="o8QmS1e9OLnTo5OwqZou"
                prompt-avatar="https://firebasestorage.googleapis.com/v0/b/highlevel-backend.appspot.com/o/locationPhotos%2Fo8QmS1e9OLnTo5OwqZou%2Fchat-widget-person?alt=media&token=e066d0e3-a241-472c-9d61-43dec67383fa"
                agency-name="Modern Doc Media"
                agency-website="https://moderndocmedia.com"
            ></chat-widget>
            <Script
                src="https://widgets.leadconnectorhq.com/loader.js"
                data-resources-url="https://widgets.leadconnectorhq.com/chat-widget/loader.js"
                strategy="idle"
            />
        </ThemeProvider>
    );
};
