import { css } from '@emotion/react';

export const px = css`
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1280px) {
        padding-left: 64px;
        padding-right: 64px;
    }

    @media (min-width: 1440px) {
        padding-left: 80px;
        padding-right: 80px;
    }
`;

export const npx = css`
    margin-left: -16px;
    margin-right: -16px;

    @media (min-width: 768px) {
        margin-left: -32px;
        margin-right: -32px;
    }

    @media (min-width: 1280px) {
        margin-left: -64px;
        margin-right: -64px;
    }

    @media (min-width: 1440px) {
        margin-left: -80px;
        margin-right: -80px;
    }
`;

export const ml = css`
    margin-left: 16px;

    @media (min-width: 768px) {
        margin-left: 32px;
    }

    @media (min-width: 1280px) {
        margin-left: 64px;
    }

    @media (min-width: 1440px) {
        margin-left: 80px;
    }
`;

export const mr = css`
    margin-right: 16px;

    @media (min-width: 768px) {
        margin-right: 32px;
    }

    @media (min-width: 1280px) {
        margin-right: 64px;
    }

    @media (min-width: 1440px) {
        margin-right: 80px;
    }
`;

export const flexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const flexSpace = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const flexStart = css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const linkHover = css`
    transition: opacity 0.3s ease-in-out;

    &:focus,
    &:hover {
        opacity: 0.7;
    }
`;

export const scrollBar = css`
    @media (pointer: fine) {
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 30px;
            background: #3a5325;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #6d8945;
            border-radius: 30px;
        }
        scrollbar-width: thin;
        scrollbar-color: #3a5325 #6d8945;
    }
`;
