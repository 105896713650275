import { ContactForm } from '@/ContactForm';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { StaticImage } from 'gatsby-plugin-image';
import { useRef } from 'react';
import { flexCenter, px } from './styles/classes';

const Section = styled.section`
    > form {
        ${px};
        padding-top: 32px;
        padding-bottom: 64px;
    }

    @media (max-width: 1023px) {
        > .gatsby-image-wrapper {
            aspect-ratio: 375 / 295;
        }
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        justify-content: unset;

        > .gatsby-image-wrapper {
            flex-shrink: 0.6;
        }

        > form {
            padding: 0;
            margin-right: 32px;
            margin-left: ${({ theme }) => theme.spacing.xl};
        }
    }

    @media (min-width: 1600px) {
        justify-content: center;
    }
`;

export const ContactUs = () => {
    const ref = useRef<HTMLFormElement | null>(null);
    const reveal = useIntersectionObserver(ref, {});

    return (
        <Section>
            <StaticImage
                src="../../../assets/images/layout/contact-form.jpg"
                alt="yeliana nikolskaya"
                width={703}
                height={964}
            />
            <ContactForm ref={ref} className={`reveal${reveal ? ' revealed' : ''}`} />
        </Section>
    );
};
