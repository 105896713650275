import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';

export const navLinkStyles = css`
    text-transform: uppercase;
    color: ${theme.colors.gray3};
    transition: color 0.3s ease-in-out;
    padding-bottom: 5px;
    border-bottom: 3px solid transparent;
    font-weight: 400;
    width: fit-content;
    text-align: left;

    &[data-selected],
    :focus,
    :hover {
        color: ${theme.colors.green2};
    }

    &.current-nav-category {
        border-color: ${theme.colors.gray2};
    }

    &[aria-current='page'] {
        border-color: ${theme.colors.gray3};
    }
`;

export const navBtnStyles = css`
    ${navLinkStyles};
    display: inline-flex;
    align-items: center;
    line-height: 150%;

    ::after {
        content: '';
        height: 7px;
        width: 7px;
        border-right: 2px solid ${theme.colors.gray3};
        border-bottom: 2px solid ${theme.colors.gray3};
        transform: rotate(-45deg);
        margin-left: 14px;
        margin-right: 2px;
        transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
        transform-origin: center;
    }

    &[aria-expanded='true'] {
        ::after {
            transform: rotate(135deg);
        }
    }
`;
