import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)<{ big?: string }>`
    ${flexCenter};
    display: inline-flex;
    padding: ${({ big }) => (big ? '24px 32px' : '12px 24px')};
    color: ${({ theme }) => theme.colors.gray1};
    background-color: ${({ theme }) => theme.colors.green2};
    transition: background-color 0.3s ease-in-out;

    :hover {
        background-color: #3a5325;
    }

    :focus-visible {
        background-color: #3a5325;
    }
`;
