exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-dawn-marie-tsx": () => import("./../../../src/pages/about-us/dawn-marie.tsx" /* webpackChunkName: "component---src-pages-about-us-dawn-marie-tsx" */),
  "component---src-pages-about-us-dr-paul-tsx": () => import("./../../../src/pages/about-us/dr-paul.tsx" /* webpackChunkName: "component---src-pages-about-us-dr-paul-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-about-us-our-philosophy-tsx": () => import("./../../../src/pages/about-us/our-philosophy.tsx" /* webpackChunkName: "component---src-pages-about-us-our-philosophy-tsx" */),
  "component---src-pages-about-us-yeliana-nikolskaya-tsx": () => import("./../../../src/pages/about-us/yeliana-nikolskaya.tsx" /* webpackChunkName: "component---src-pages-about-us-yeliana-nikolskaya-tsx" */),
  "component---src-pages-appointment-tsx": () => import("./../../../src/pages/appointment.tsx" /* webpackChunkName: "component---src-pages-appointment-tsx" */),
  "component---src-pages-dana-point-tsx": () => import("./../../../src/pages/dana-point.tsx" /* webpackChunkName: "component---src-pages-dana-point-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photo-gallery-tsx": () => import("./../../../src/pages/photo-gallery.tsx" /* webpackChunkName: "component---src-pages-photo-gallery-tsx" */),
  "component---src-pages-price-list-tsx": () => import("./../../../src/pages/price-list.tsx" /* webpackChunkName: "component---src-pages-price-list-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-treatments-tsx": () => import("./../../../src/pages/treatments.tsx" /* webpackChunkName: "component---src-pages-treatments-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-treatment-tsx": () => import("./../../../src/templates/treatment.tsx" /* webpackChunkName: "component---src-templates-treatment-tsx" */)
}

