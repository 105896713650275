import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { px } from './layout/styles/classes';

export const Article = styled.article<{ big?: boolean; miniCardsGrid?: boolean }>`
    ${px};
    max-width: ${({ big }) => (big ? '1064px' : '820px')};
    box-sizing: content-box;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-left: auto;
    margin-right: auto;

    > h2 {
        margin: 32px 0;
    }

    > h5 {
        margin: 20px 0;
    }

    > h1:first-of-type,
    > h2:first-of-type,
    > h5:first-of-type {
        margin-top: 0;
    }

    > p:last-of-type:not(:first-of-type),
    > ul:last-of-type:not(:first-of-type) {
        margin-bottom: 0;
    }

    @media (min-width: 1280px) {
        padding-top: 120px;
        padding-bottom: 120px;

        > h2 {
            margin: 64px 0;

            &:first-of-type {
                margin-top: 0;
            }
        }

        > h5 {
            margin: 24px 0;
        }

        > p {
            margin: 32px 0;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type:not(:first-of-type) {
                margin-bottom: 0;
            }
        }
    }

    ${({ miniCardsGrid }) =>
        miniCardsGrid &&
        css`
            display: grid;
            gap: 32px;
            grid-template-columns: repeat(auto-fit, min(100%, 328px));

            @media (min-width: 1024px) {
                grid-row-gap: 64px;
            }

            @media (min-width: 1280px) {
                grid-row-gap: 80px;
                grid-column-gap: 40px;
            }
        `};
`;
