import { flexCenter } from '@/layout/styles/classes';
import rightArrow from '@a/icons/right-arrow.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)<{ white?: string; arrow?: string; big?: string }>`
    ${flexCenter};
    display: inline-flex;
    padding: ${({ big }) => (big ? '24px 32px' : '12px 24px')};
    box-shadow: inset 0 0 0 1px
        ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.green2)};
    position: relative;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    color: ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.green2)};
    background-color: none;

    ${({ arrow }) =>
        arrow &&
        css`
            ::after {
                content: url(${rightArrow});
                margin-left: 18px;
            }
        `};

    :hover {
        background-color: ${({ white }) =>
            white ? 'rgba(109, 137, 69, 0.4)' : 'rgba(208, 231, 213, 0.5)'};
    }

    :focus-visible {
        background-color: ${({ white }) =>
            white ? 'rgba(109, 137, 69, 0.4)' : 'rgba(208, 231, 213, 0.5)'};
    }
`;
