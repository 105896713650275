import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flexCenter } from './layout/styles/classes';
import { lgTxt } from './layout/styles/Typography';

export const Label = styled.label`
    display: block;
    text-transform: capitalize;
    margin-bottom: 5px;

    > span {
        color: ${({ theme }) => theme.colors.pink2};
        margin-left: 4px;
    }
`;

export const Input = styled.input<{ rows?: number }>`
    width: 100%;
    display: block;
    outline: none;
    border: none;
    outline: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    transition: border-color 0.3s ease-in, background-color 0.3s ease-in;
    color: ${({ theme }) => theme.colors.gray3};
    padding: 5px 0;
    margin-bottom: 20px;

    @media (pointer: fine) {
        :hover {
            background-color: #9fc5a614;
        }
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.green3};
    }

    ::placeholder {
        color: ${({ theme }) => theme.colors.gray2};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.gray3};
    }

    :focus:invalid {
        border-color: ${({ theme }) => theme.colors.pink2};
    }
`;

const StyledThanks = styled.div<{ submit: boolean; newsletter?: boolean }>`
    ${flexCenter};
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme, newsletter }) =>
        newsletter ? theme.colors.green3 : theme.colors.gray1};

    > h2 {
        margin-top: 0;
    }

    > p {
        margin-bottom: 0;
        ${lgTxt};
    }
    @media (min-width: 768px) {
        padding: 64px;
    }

    ${({ newsletter }) =>
        newsletter &&
        css`
            padding: 0 !important;

            > h4 {
                margin: 0 !important;
            }
        `};
`;

export const Thanks = ({ submit, newsletter }: { submit: boolean; newsletter?: boolean }) => (
    <StyledThanks submit={submit} newsletter={newsletter}>
        {newsletter ? <h4>Thank you</h4> : <h2>Thank you</h2>}
        {newsletter ? '' : <p>Someone will be right with you shortly!</p>}
    </StyledThanks>
);
