type Seo = {
    [index: string]: {
        t: string;
        d?: string;
    };
};

export const seoData: Seo = {
    'about-us': {
        t: 'About Beauty Religion in West Hollywood, CA',
        d: 'Discover the philosophy behind Beauty Religion, where we blend science and aesthetics to offer you the ultimate beauty experience. Contact us today!',
    },

    shop: {
        t: 'Amazing Products that we trusts | Beauty Religion',
        d: 'List of products info and price that we recommend to our clients at west hollywood and dana point.',
    },

    'dana-point': {
        t: 'Dana Point | Beauty Religion',
        d: 'Indulge in beauty at Dana Point! Discover premium beauty services and products at Beauty Religion. Experience luxury and transformation. Call us today!',
    },
    'price-list': {
        t: 'Complete Price List of Beauty Religion Services',
        d: 'Check out our comprehensive price list for various beauty treatments at Beauty Religion. Quality services at competitive prices. Contact us for more info!',
    },

    appointment: {
        t: 'Book an Appointment in West Hollywood, CA | Beauty Religion',
        d: 'Ready for a beauty transformation? Book your appointment with Beauty Religion today and start your journey to a more radiant you. Make an appointment today!',
    },

    'yeliana-nikolskaya': {
        t: 'Meet Yeliana | West Hollywood | Beauty Religion',
        d: 'Introducing Yeliana Nikolskaya, our esteemed skincare specialist. Dive into her journey and expertise in providing unparalleled skincare solutions at Beauty Religion.',
    },

    'dr-paul': {
        t: 'Meet Medical Director, Paul Laband, MD | Beauty Religion',
        d: 'Paul Laband, MD, is board-certified internist with almost 3 decades of experience in primary and hospital-based care. ',
    },

    'our-philosophy': {
        t: 'Our Philosophy | Beauty Religion',
        d: 'Our staff is trained to perform all non-invasive cosmetic procedures, and we developed most comprehensive selection of non-surgical cosmetic treatment options.',
    },

    'privacy-policy': {
        t: 'Privacy Policy | Beauty Religion',
        d: 'Please read our privacy policy on this page effective from April 18, 2016.',
    },

    'photo-gallery': {
        t: 'Before And After Photos | Beauty Religion',
        d: 'Explore our photo gallery to witness stunning beauty transformations achieved at Beauty Religion. Real results that speak volumes. Contact us today!',
    },

    treatments: {
        t: 'Cosmetic Dermatology Treatments  in West Hollywood, CA | Beauty Religion',
        d: 'Explore a wide range of advanced beauty treatments at Beauty Religion. From skin rejuvenation to aesthetic enhancements, we offer solutions tailored to your needs.',
    },

    'alpha-stim-microcurrent-treatment': {
        t: 'Alpha Stim Microcorrent Treatment in West Hollywood, CA | Beauty Religion',
        d: 'Unlock the secret to non-invasive facial rejuvenation with our Alpha-Stim Microcurrent Treatment. Say goodbye to stress and hello to relaxation and beauty.',
    },

    'carboxytherapy-for-stretch-marks-and-cellulite': {
        t: 'Carboxytherapy Services in California | Beauty Religion',
        d: 'Beauty Religion offers carboxytherapy, mesotherapy, and other skin tag removal treatments at our Los Angeles medical spa. Book an appointment Today!',
    },

    'cliovana-for-intimate-wellness': {
        t: 'Cliovana Treatment Services in West Hollywood, CA | Beauty Religion',
        d: 'Explore Cliovana for intimate wellness at Beauty Religion. Enhance your sexual health and well-being with our revolutionary treatment. Book an appointment now!',
    },

    'exosome-facial': {
        t: 'Exosome Facial Services In West Hollywood, CA | Beauty Religion',
        d: 'Unlock youthful skin with our exosome facial in West Hollywood at Beauty Religion. Experience enhanced cell regeneration for a vibrant complexion.',
    },

    'iv-therapy': {
        t: 'IV Therapy in West Hollywood, CA | Beauty Religion',
        d: 'Revitalize your body with our IV Therapy treatments at Beauty Religion. Tailored to boost energy, enhance wellness, and promote overall health.',
    },

    nad: {
        t: 'Nicotinamide Adenine Dinucleotide Therapy | Beauty Religion',
        d: "A simple NAD+ IV infusion can help activate your brain's neuron function by helping cells regenerate and protect them against damage. ",
    },

    'mayers-cocktail': {
        t: 'Myers Cocktail in Los Angeles | Beauty Religion',
        d: 'The Myers’ cocktail is a popular formula among complementary and alternative medicine providers for IV vitamin therapy. ',
    },

    'laser-treatments': {
        t: 'Laser Treatments in West Hollywood, CA | Beauty Religion',
        d: 'Explore advanced Laser Treatments at Beauty Religion for skin rejuvenation, hair removal, and more. Our state-of-the-art technology ensures lasting results.',
    },

    'ipl-photofacial': {
        t: 'Laser Treatments in West Hollywood, CA | Beauty Religion',
        d: 'Explore advanced Laser Treatments at Beauty Religion for skin rejuvenation, hair removal, and more. Our state-of-the-art technology ensures lasting results.',
    },

    'laser-hair-removal': {
        t: 'Laser Hair Removal West Hollywood | Beauty Religion',
        d: ' Looking for laser hair removal in West Hollywood, CA? Beauty Religion has the skills, equipment, and expertise to remove hair from anywhere on your body.',
    },

    'erbium-laser': {
        t: 'Erbium Laser Skin Resurfacing Service In CA | Beauty Religion',
        d: 'Erbium-YAG lasers have been used for laser resurfacing of human skin. Example uses include treating acne scarring, deep rhytides, and melasma.Call Now!',
    },

    'vein-diode-laser': {
        t: 'Vein Diode Laser Treatment In West Hollywood | Beauty Religion',
        d: 'Beauty Religion offers vein treatments to reduce the appearance of spider veins in the face and body for clients in Beverly Hills, Hollywood and Los Angeles.',
    },

    'tattoo-removal-yag-laser': {
        t: 'Tattoo Removal YAG Laser in West Hollywood | Beauty Religion',
        d: 'We work with the latest and most modern laser system for tattoo removal, the Q-Switched Nd: YAG laser. Tattoo removal completed with Nd: YAG laser technology.',
    },

    'dermal-fillers': {
        t: 'Dermal Fillers in West Hollywood, CA | Beauty Religion',
        d: 'Discover radiance with our Dermal Fillers at Beauty Religion. Designed to reduce wrinkles, restore volume, and enhance facial contours for a rejuvenated appearance.',
    },

    'juvederm-voluma-restylane-kysse-and-versa': {
        t: 'Aesthetic Services in West Hollywood, CA | Beauty Religion',
        d: 'Enhance your beauty with Juvederm, Voluma, and more at Beauty Religion. Get top aesthetic services in West Hollywood, CA. Call now to rediscover your radiance!',
    },

    radiesse: {
        t: 'Radiesse Treatment In West Hollywood | Beauty Religion',
        d: 'Here at Beauty Religion, we provide Radiesse treatment for our clients residing in the West Hollywood, Beverly Hills, and Los Angeles area. Call us Today!',
    },

    sculptra: {
        t: 'Sculptra Aesthetics Services in Los Angele | Beauty Religion',
        d: 'Sculptra works to correct shallow to deep facial wrinkles and folds due to its bio-stimulatory effect on collagen. Call us at our Los Angeles location.',
    },

    bellafill: {
        t: 'FDA Approved Bellafill Dermal Filler CA | Beauty Religion',
        d: 'Bellafill creates a lasting improvement in your skin that restores, rather than simply fills, for natural-looking results. Our Bellafill is FDA Approved.',
    },

    'mesotherapy-bio-revitalization': {
        t: 'West Hollywood Mesotherapy | Skin Rejuvination | Beauty Religion',
        d: "Enhance your skin's vitality with mesotherapy in West Hollywood at Beauty Religion. Brighten your complexion and firm skin with our expert treatments.",
    },

    'kybella-for-double-chin': {
        t: 'Kybella Non-Surgical Injectable Technique in West Hollywood, CA | Beauty Religion',
        d: 'Say goodbye to the double chin with Kybella treatment at Beauty Religion. Achieve a defined jawline and enhanced facial contours with this breakthrough solution.',
    },

    'botox-neurotoxin': {
        t: 'BOTOX and Other Neurotoxins in Los Angeles | Beauty Religion',
        d: 'BOTOX and Other Neurotoxins Cosmetic treatments in LA and Beverly Hills are fast and virtually painless. The procedure only takes about 10 to 15 minutes.',
    },

    botox: {
        t: 'Botox Services in West Hollywood, CA | Beauty Religion',
        d: 'Discover smoother, youthful skin with our Botox services in West Hollywood, CA. Expert treatments for a refreshed and confident you. Book with us today!',
    },

    dysport: {
        t: 'Dysport Botox in West Hollywood, CA | Beauty Religion',
        d: "Boost your skin's youthful appearance with Dysport Botox in West Hollywood, CA. We provide expert injections to reduce wrinkles & enhance beauty. Book now!",
    },

    jeuveau: {
        t: 'Jeuveau Botox in West Hollywood, CA | Beauty Religion',
        d: 'Revitalize your look with Jeuveau Botox in West Hollywood, CA. Get rid of wrinkles and rejuvenate your appearance. Call to schedule your appointment today!',
    },

    'plasma-fibroblast': {
        t: 'Plasma Fibroblast Treatment in West Hollywood, CA | Beauty Religion',
        d: 'Experience Plasma Fibroblast, an innovative skin tightening treatment at Beauty Religion. Minimize wrinkles and achieve a youthful glow. Contact us today!',
    },

    'vi-peels': {
        t: 'Getting a Chemical Peel in Los Angeles | Beauty Religion',
        d: 'Thinking about getting a chemical peel? We offer a variety of peels, including the VI Peel Precision as well as the VI Peel Precision Plus from VI Aesthetics.',
    },

    'chemical-lightening-peel': {
        t: 'Chemical Lightening Peel in West Hollywood | Beauty Religion',
        d: 'A Chemical Lightening Peel that is used against hyperpigmentation and melasma. It also has a positive anti-aging effect. Call Beauty Religion Today!',
    },

    'pdo-thread-lift': {
        t: 'PDO Thread Lift in West Hollywood, CA | Beauty Religion',
        d: 'Elevate your beauty with PDO Thread Lift treatments at Beauty Religion. Experience a non-surgical facelift that delivers lasting results. Book with us today!',
    },

    'prf-and-prp-prf-gel-naturelle': {
        t: 'PRP Facial With Microneedling in West Hollywood',
        d: 'PRP Therapy stands for Platelet Rich Plasma Therapy which helps accelerate the healing process of injured tissues and ensures the best possible patient outcome.',
    },

    'sclerotherapy-for-varicose-veins': {
        t: 'Sclerotherapy in West Hollywood, CA | Varicose Veins',
        d: 'Discover sclerotherapy for varicose veins in West Hollywood at Beauty Religion. Expert care to alleviate pain & enhance beauty. Schedule your consultation.',
    },

    'skin-tightening-treatments': {
        t: 'Skin Tightening Treatments in West Hollywood, CA | Beauty Religion',
        d: 'Discover effective skin tightening treatments at Beauty Religion in West Hollywood, CA. Achieve youthful, firmer skin with our advanced techniques.',
    },

    'scarlet-srf-micro-needling': {
        t: 'Scarlet SRF Skin Treatment West Hollywood | Beauty Religion',
        d: 'Scarlet SRF is an innovative skin treatment in West Hollywood that combines radiofrequency (RF) with micro needling to restore aging skin!',
    },

    'sofwave-ultrasound': {
        t: 'SofWave Ultrasound in West Hollywood | Beauty Religion',
        d: 'Sofwave is the latest technology to revitalize skin by addressing fine lines and wrinkles through innovative stimulation of the production of new collagen.',
    },

    'agnes-rf-micro-needling': {
        t: 'Agnes RF Mirconeedling in Los Angeles | Beauty Religion',
        d: 'AGNES is a unique nonsurgical radiofrequency device that uses micro-insulated needles to precisely penetrate the skin at specific points, delivering RF energy. ',
    },

    'qwo-injections-for-cellulite': {
        t: 'QWO Interjections for Cellulite in West Hollywood, CA | Beauty Religion',
        d: 'Transform your skin with QWO injections for cellulite at Beauty Religion. Our innovative treatment reduces cellulite appearance for smoother, more youthful-looking skin.',
    },

    'ozone-therapy-aesthetic-injections': {
        t: 'Ozone Therapy Aesthetic Injections in West Hollywood, CA | Beauty Religion',
        d: 'Experience the cutting-edge Ozone Therapy Aesthetic Injections at Beauty Religion. A unique treatment that rejuvenates skin, promotes healing, and enhances appeal.',
    },

    'prx-derm-perfexion': {
        t: 'PRX Derm Perfexion in West Hollywood, CA | Beauty Religion',
        d: 'Discover PRX-Derm Perfexion, a revolutionary skincare treatment at Beauty Religion. Experience rejuvenated skin and a radiant complexion like never before.',
    },

    renuva: {
        t: 'Renuva in Los Angeles | Beauty Religion ',
        d: 'Renuva is a groundbreaking treatment that, when injected, gradually replaces age-related fat loss with your body’s own fat. ',
    },

    'lightening-peel-for-melasma': {
        t: 'Lightening Peel for Melasma in beverly hill',
        d: 'Unique exfoliating treatment using vitamin A and other Melanin inhibitors as a therapeutic element against photoaging.',
    },

    'clear-and-brilliant': {
        t: 'Clear & Brilliant Laser Treatment in West Hollywood, CA | Beauty Religion',
        d: 'Experience the Clear and Brilliant treatment at Beauty Religion to rejuvenate your skin, reduce pores, and enhance your natural glow. Contact us today! ',
    },

    'bodysculp-fat-reduction': {
        t: 'BodySculp Fat Reduction in West Hollywood | Beauty Religion',
        d: 'Transform your body with BodySculp fat reduction in West Hollywood at Beauty Religion. Quick, effective treatment for a sculpted physique with zero downtime.',
    },

    'sylfirmx-rf-microneedling-skin-tightening': {
        t: 'Sylfirm X RF Microneedling Los Angeles | Beauty Religion',
        d: 'Experience Sylfirm X RF microneedling in Los Angeles at Beauty Religion. FDA-cleared for comprehensive skin rejuvenation with zero downtime. Book today.',
    },

    'botox-in-dana-point': {
        t: 'Botox Services in Dana Point, CA | Beauty Religion',
        d: 'Experience rejuvenation with Botox in Dana Point. Discover expert treatments at Beauty Religion for a refreshed and youthful appearance. Book with us today!',
    },
    'cryo-skin-therapy': {
        t: 'CRYO Skin Therapy | Beauty Religion',
        d: 'CRYO Skin Therapy is a relaxing, natural and non-invasive way to aid in the tightening of the skin. Experience CRYO Skin Therapy services at Beauty religion today!',
    },
};
