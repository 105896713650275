import { flexCenter, flexSpace, px } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { useRef } from 'react';

const Section = styled.section`
    @media (min-width: 1024px) {
        ${flexCenter};
        justify-content: flex-end;
    }

    @media (min-width: 1600px) {
        margin: 0 auto;
        max-width: max-content;
    }
`;

const Iframe = styled.iframe`
    width: 100%;
    height: 320px;
    border: 0;

    @media (min-width: 600px) {
        height: 400px;
    }

    @media (min-width: 1024px) {
        max-width: 740px;
        height: 1082px;
        flex-shrink: 2;
        order: 1;
    }

    @media (min-width: 1280px) {
        height: 1099px;
    }

    @media (min-width: 1600px) {
        width: 740px;
    }
`;

const Hours = styled.div`
    ${px};
    padding-top: 32px;
    padding-bottom: 64px;

    > h2 {
        margin: 0;
    }

    > h5 {
        margin: 24px 0 16px;
        color: ${({ theme }) => theme.colors.green2};

        > span {
            font-size: 1rem;
        }
    }

    > div {
        max-width: 426px;

        > p {
            ${flexSpace};
            align-items: flex-start;
            margin: 0;

            :not(:last-of-type) {
                margin-bottom: 12px;
            }

            > span {
                :first-of-type {
                    font-weight: 700;
                }

                :last-of-type {
                    text-align: right;
                }
            }
        }
    }

    @media (min-width: 1024px) {
        padding: 24px 0 !important;
        margin: 0 ${({ theme }) => theme.spacing.xxl} 0 32px;
        width: 426px;
        min-width: 363px;

        > div {
            > p:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }
`;

export const HoursMap = () => {
    const { category } = useLocationData();

    const firstRef = useRef<HTMLDivElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLDivElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    const thirdRef = useRef<HTMLDivElement | null>(null);
    const thirdReveal = useIntersectionObserver(thirdRef, {});

    return (
        <Section>
            <Iframe
                title="beauty religion locations on map"
                src="https://www.google.com/maps/d/u/2/embed?mid=1fLgECJ7NB_hPVBHx6C0YWQmT0tywOk4&ehbc=2E312F"
                allowFullScreen
                loading="lazy"
                id="map"
            ></Iframe>
            <Hours>
                <h2>office hours</h2>
                <h5>West Hollywood</h5>
                <div
                    ref={firstRef}
                    className={category ? '' : `reveal ${firstReveal ? ' revealed' : ''}`}
                >
                    <p>
                        <span>Monday</span>
                        <span>Closed</span>
                    </p>
                    <p>
                        <span>Tuesday</span>
                        <span>1:00 PM - 6:30 PM</span>
                    </p>
                    <p>
                        <span>Wednesday</span>
                        <span>Open at Dana Point Location</span>
                    </p>
                    <p>
                        <span>Thursday</span>
                        <span>Open at Dana Point Location</span>
                    </p>
                    <p>
                        <span>Friday</span>
                        <span>2:30 PM - 7:00 PM</span>
                    </p>
                    <p>
                        <span>Saturday</span>
                        <span>Closed</span>
                    </p>
                    <p>
                        <span>Sunday</span>
                        <span>12:30 PM - 5:00 PM</span>
                    </p>
                </div>
                <h5 style={{ marginTop: '32px' }}>
                    Dana Point <span>(by appointments only)</span>
                </h5>
                <div
                    ref={secondRef}
                    className={
                        category
                            ? ''
                            : `reveal reveal-delay-mobile-2${secondReveal ? ' revealed' : ''}`
                    }
                >
                    <p>
                        <span>Wednesday</span>
                        <span>2:00 PM - 7:00 PM</span>
                    </p>

                    <p>
                        <span>Thursday</span>
                        <span>12:00 PM - 7:00 PM</span>
                    </p>
                </div>
                <h6 style={{ margin: '32px 0 16px' }}>Aesthetician Schedule</h6>
                <div
                    ref={thirdRef}
                    className={
                        category
                            ? ''
                            : `reveal reveal-delay-mobile-4${thirdReveal ? ' revealed' : ''}`
                    }
                >
                    <p>
                        <span>Sunday</span>
                        <span>10:00 AM - 4:00 PM</span>
                    </p>
                    <p>
                        <span>Monday</span>
                        <span>8:00 AM - 9:00 PM</span>
                    </p>
                    <p>
                        <span>Tuesday</span>
                        <span>9:00 AM - 7:00 PM</span>
                    </p>
                    <p>
                        <span>Wednesday</span>
                        <span>8:00 AM - 9:00 PM</span>
                    </p>
                    <p>
                        <span>Thursday</span>
                        <span>9:00 AM - 1:00 PM</span>
                    </p>
                    <p>
                        <span>Friday</span>
                        <span>8:00 AM - 9:00 PM</span>
                    </p>
                    <p>
                        <span>Saturday</span>
                        <span>8:00 AM - 9:00 PM</span>
                    </p>
                </div>
            </Hours>
        </Section>
    );
};
