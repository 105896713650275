import CLink from '@/CLink';
import CallBtn from '@/buttons/CallBtn';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { StaticImage } from 'gatsby-plugin-image';
import { useRef } from 'react';
import { info } from './NavLinks';
import { flexCenter, px } from './styles/classes';

const Section = styled.section`
    @media (min-width: 1024px) {
        ${flexCenter};
        justify-content: unset;
    }

    @media (min-width: 1600px) {
        width: max-content;
        margin: 0 auto;
    }
`;

const Content = styled.div`
    ${px};
    padding-top: 32px;
    padding-bottom: 64px;
    max-width: max-content;

    > h2 {
        margin: 0 0 32px;
    }

    > p {
        display: grid;
        grid-template-columns: 14ch auto;
        margin-bottom: 32px;

        > strong {
            margin-right: 16px;
        }

        p {
            margin: -2px 0 0;
            font-size: 0.875rem;
            font-style: italic;
        }

        a {
            text-decoration: underline;
            transition: color 0.3s ease-in-out;
            word-break: break-word;

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.green2};
            }
        }
    }

    @media (min-width: 1024px) {
        margin-left: ${({ theme }) => theme.spacing.lg};
        margin-right: 32px;
        padding: 0;
    }

    @media (min-width: 1280px) {
        > h2 {
            margin-bottom: 64px;
        }
    }
`;

export const Guest = () => {
    const ref = useRef<HTMLParagraphElement | null>(null);
    const reveal = useIntersectionObserver(ref, {});

    const { category } = useLocationData();

    return (
        <Section>
            <StaticImage
                src="../../../assets/images/layout/guest.jpg"
                alt=" yeliana nikolskaya standing in-front of beauty religion place"
                width={703}
                height={516}
            />
            <Content ref={ref} className={category ? '' : `reveal ${reveal ? ' revealed' : ''}`}>
                <h2>Be our guest</h2>
                <p>
                    <strong>West Hollywood</strong>
                    <CLink to={info.address.link}>{info.address.text}</CLink>
                </p>
                <p>
                    <strong>Dana Point</strong>
                    <div>
                        <CLink to={info.secondAddress.link}>{info.secondAddress.text}</CLink>
                        <p>East of Golden Lantern. Located inside Riviera Laser Studios</p>
                    </div>
                </p>
                <p>
                    <strong>Email</strong>
                    <CLink to={info.email.link}>{info.email.text}</CLink>
                </p>
                <CallBtn />
            </Content>
        </Section>
    );
};
