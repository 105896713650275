import { ReactComponent as Facebook } from '@a/icons/facebook.svg';
import { ReactComponent as Instagram } from '@a/icons/instagram.svg';
import { ReactComponent as Yelp } from '@a/icons/yelp.svg';
import styled from '@emotion/styled';
import CLink from './CLink';
import { info } from './layout/NavLinks';
import { flexCenter, flexSpace } from './layout/styles/classes';

const Icons = styled.div<{ dark?: boolean; pink?: boolean }>`
    ${flexSpace};
    max-width: 140px;

    > a {
        ${flexCenter};
        margin-right: 40px;

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${({ theme, dark, pink }) =>
                dark ? theme.colors.gray3 : pink ? theme.colors.pink1 : theme.colors.gray1};
        }

        :hover {
            path {
                fill: ${({ theme, dark, pink }) =>
                    dark ? theme.colors.gray2 : pink ? theme.colors.pink2 : theme.colors.green1};
            }
        }

        :focus-visible {
            path {
                fill: ${({ theme, dark, pink }) =>
                    dark ? theme.colors.gray2 : pink ? theme.colors.pink2 : theme.colors.green1};
            }
        }

        :last-of-type {
            margin-right: 0;
        }
    }
`;

export const SocialIcons = ({
    className,
    dark,
    pink,
}: {
    className?: string;
    dark?: boolean;
    pink?: boolean;
}) => {
    return (
        <Icons className={className} dark={dark} pink={pink}>
            <CLink to={info.social.instagram} aria-label="beauty religion instagram">
                <Instagram />
            </CLink>
            <CLink to={info.social.facebook} aria-label="beauty religion facebook">
                <Facebook />
            </CLink>
            <CLink to={info.social.yelp} aria-label="beauty religion yelp">
                <Yelp />
            </CLink>
        </Icons>
    );
};
