export const info = {
    email: {
        link: 'mailto:contact@beautyreligion.com',
        text: 'contact@beautyreligion.com',
    },
    phone: {
        link: 'tel:+13102996416',
        text: '(310) 299 - 6416',
    },
    address: {
        text: '8701 Santa Monica Blvd, West Hollywood, CA 90069',
        link: 'https://goo.gl/maps/fVFm5wJGeuExZNef7',
    },
    secondAddress: {
        text: '34189 Pacific Coast Hwy, Suite 201, Dana Point CA 92629',
        link: 'https://goo.gl/maps/Gz9mhrwZDK4f2ER56',
    },
    social: {
        facebook: 'https://www.facebook.com/beautyreligionaesthetics/',
        instagram: 'https://www.instagram.com/beauty_religion_aesthetic/',
        yelp: 'https://www.yelp.com/biz/beauty-religion-west-hollywood-2/',
    },
    reviewLink:
        'https://www.google.com/search?q=Beauty+Religion++Santa+Monica+Boulevard%2C+West+Hollywood&rlz=1C1CHBF_enPK1016PK1016&ei=jw1QY6iGJpjckgW_0pS4Aw&ved=0ahUKEwjoibOXxOz6AhUYrqQKHT8pBTcQ4dUDCA8&uact=5&oq=Beauty+Religion++Santa+Monica+Boulevard%2C+West+Hollywood&gs_lcp=Cgdnd3Mtd2l6EAMyBQghEKABMgUIIRCgATIFCCEQoAEyCAghEBYQHhAdMggIIRAWEB4QHTIICCEQFhAeEB0yCAghEBYQHhAdMggIIRAWEB4QHToKCAAQRxDWBBCwAzoFCAAQgAQ6BggAEBYQHkoECEEYAEoECEYYAFDrAliFBWCLCWgBcAF4AIABlAKIAdIDkgEFMC4xLjGYAQCgAQGgAQLIAQjAAQE&sclient=gws-wiz#lrd=0x80c2b92942f43ce1:0x50fe1c2318c9864f,1,,,',
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
        links: [
            {
                text: 'yeliana nikolskaya',
                link: '/about-us/yeliana-nikolskaya/',
            },
            {
                text: 'dr. paul laband',
                link: '/about-us/dr-paul/',
            },
            {
                text: 'dawn marie',
                link: '/about-us/dawn-marie/',
            },
            {
                text: 'our philosophy',
                link: '/about-us/our-philosophy/',
            },
        ],
    },
    {
        text: 'treatments',
        link: '/treatments/',
        links: [
            {
                text: 'botox / neurotoxin',
                link: '/treatments/botox-neurotoxin/',
                links: [
                    {
                        text: 'botox',
                        link: '/treatments/botox-neurotoxin/botox/',
                    },
                    {
                        text: 'botox in dana point',
                        link: '/treatments/botox-neurotoxin/botox-in-dana-point/',
                    },
                    {
                        text: 'dysport',
                        link: '/treatments/botox-neurotoxin/dysport/',
                    },
                    {
                        text: 'jeuveau',
                        link: '/treatments/botox-neurotoxin/jeuveau/',
                    },
                ],
            },
            {
                text: 'dermal fillers',
                link: '/treatments/dermal-fillers/',
                links: [
                    {
                        text: 'juvederm voluma, restylane kysse and versa',
                        link: '/treatments/dermal-fillers/juvederm-voluma-restylane-kysse-and-versa/',
                    },
                    {
                        text: 'radiesse',
                        link: '/treatments/dermal-fillers/radiesse/',
                    },
                    {
                        text: 'sculptra',
                        link: '/treatments/dermal-fillers/sculptra/',
                    },
                    {
                        text: 'renuva',
                        link: '/treatments/dermal-fillers/renuva/',
                    },
                ],
            },
            {
                text: 'laser treatments',
                link: '/treatments/laser-treatments/',
                links: [
                    {
                        text: 'erbium laser',
                        link: '/treatments/laser-treatments/erbium-laser/',
                    },
                    {
                        text: 'ipl photofacial',
                        link: '/treatments/laser-treatments/ipl-photofacial/',
                    },
                    {
                        text: 'laser hair removal',
                        link: '/treatments/laser-treatments/laser-hair-removal/',
                    },
                    {
                        text: 'tattoo removal yag laser',
                        link: '/treatments/laser-treatments/tattoo-removal-yag-laser/',
                    },
                    {
                        text: 'vein diode laser',
                        link: '/treatments/laser-treatments/vein-diode-laser/',
                    },
                ],
            },
            {
                text: 'pdo thread lift',
                link: '/treatments/pdo-thread-lift/',
            },
            {
                text: 'prf & prp, prf gel naturelle',
                link: '/treatments/prf-and-prp-prf-gel-naturelle/',
            },
            {
                text: 'skin tightening treatments',
                link: '/treatments/skin-tightening-treatments/',
                links: [
                    {
                        text: 'agnes rf micro-needling',
                        link: '/treatments/skin-tightening-treatments/agnes-rf-micro-needling/',
                    },
                    {
                        text: 'sofwave ultrasound',
                        link: '/treatments/skin-tightening-treatments/sofwave-ultrasound/',
                    },
                    {
                        text: 'plasma fibroblast',
                        link: '/treatments/skin-tightening-treatments/plasma-fibroblast/',
                    },
                ],
            },
            {
                text: 'lightening peel for melasma',
                link: '/treatments/lightening-peel-for-melasma/',
            },
            {
                text: 'carboxytherapy for stretch marks & cellulite',
                link: '/treatments/carboxytherapy-for-stretch-marks-and-cellulite/',
            },
            {
                text: 'cliovana for intimate wellness',
                link: '/treatments/cliovana-for-intimate-wellness/',
            },
            {
                text: 'exosome facial',
                link: '/treatments/exosome-facial/',
            },
            {
                text: 'kybella for double chin',
                link: '/treatments/kybella-for-double-chin/',
            },
            {
                text: 'mesotherapy / bio revitalization',
                link: '/treatments/mesotherapy-bio-revitalization/',
            },
            {
                text: 'sclerotherapy for varicose veins',
                link: '/treatments/sclerotherapy-for-varicose-veins/',
            },
            {
                text: 'clear & brilliant',
                link: '/treatments/clear-and-brilliant/',
            },
            {
                text: 'alpha stim microcurrent treatment',
                link: '/treatments/alpha-stim-microcurrent-treatment/',
            },
            {
                text: 'bodysculp fat reduction',
                link: '/treatments/bodysculp-fat-reduction/',
            },
            {
                text: 'sylfirmx rf microneedling skin tightening',
                link: '/treatments/sylfirmx-rf-microneedling-skin-tightening/',
            },
            {
                text: 'cryo skin therapy',
                link: '/treatments/cryo-skin-therapy/',
            },
            {
                text: 'carboxy facial',
                link: '/treatments/carboxy-facial/',
            },
        ],
    },
    {
        text: 'photo gallery',
        link: '/photo-gallery/',
    },
    {
        text: 'price list',
        link: '/price-list/',
    },
    {
        text: 'shop',
        link: '/shop/',
    },
    {
        text: 'Dana Point',
        link: '/dana-point/',
    },
    {
        text: 'blog',
        link: '/blog/',
    },
    {
        text: 'appointment',
        link: '/appointment/',
    },
];

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'treatments',
        link: '/treatments/',
    },
    {
        text: 'Gallery',
        link: '/photo-gallery/',
    },
    {
        text: 'price list',
        link: '/price-list/',
    },
    {
        text: 'shop',
        link: '/shop/',
    },
    {
        text: 'Dana Point',
        link: '/dana-point/',
    },
    {
        text: 'Schedule an Appointment',
        link: '/appointment/',
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];

export const flatArray = (links: NavLinksTypes) => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(links);
    return flat;
};

const flatLinks = flatArray(navLinks);

export function getNavLink(text: string) {
    const theText = text === 'botox neurotoxin' ? 'botox / neurotoxin' : text;
    const linkObj = flatLinks.find(data => data.text === theText.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
