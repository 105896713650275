import { Input, Label, Thanks } from '@/FormComponents';
import { PrimaryBtn } from '@/buttons';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import { forwardRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { flexSpace } from './layout/styles/classes';

const Form = styled.form`
    max-width: 522px;
    box-sizing: content-box;
    position: relative;
    scroll-margin-top: 20px;

    > h2 {
        margin: 00;

        + p {
            margin: 16px 0 32px;
            color: ${({ theme }) => theme.colors.gray2};
        }
    }

    > button:last-of-type {
        margin-top: 12px;
        width: 100%;
    }

    @media (min-width: 1280px) {
        > h2 + p {
            margin: 24px 0 48px;
        }

        > button:last-of-type {
            margin-top: 28px;
        }
    }
`;

const Flex = styled.div`
    @media (min-width: 450px) {
        ${flexSpace};

        > div {
            width: calc(50% - 10px);
        }
    }
`;

// eslint-disable-next-line
export const ContactForm = forwardRef<HTMLFormElement>((props, ref) => {
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = Object.fromEntries(new FormData(e.currentTarget));

        setLoading(true);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
        setLoading(false);
        e.target.reset();
    };

    const { category } = useLocationData();

    return (
        <Form
            onSubmit={handleSubmit}
            id="contact"
            ref={ref}
            className={category ? '' : props.className}
        >
            <h2>Contact us</h2>
            <p>
                Do you have any questions or requests? We are here and happy to help. Simply use our
                enquiry form.
            </p>
            <Flex>
                <div>
                    <Label htmlFor="firstName">
                        First Name<span>*</span>
                    </Label>
                    <Input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        required
                    />
                </div>

                <div>
                    <Label htmlFor="lastName">
                        Last Name<span>*</span>
                    </Label>
                    <Input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        required
                    />
                </div>
            </Flex>

            <Label htmlFor="email">
                Email address<span>*</span>
            </Label>
            <Input
                type="email"
                id="email"
                name="email"
                required
                placeholder="e.g: example@gmail.com"
            />

            <Label htmlFor="phone">
                Phone<span>*</span>
            </Label>
            <NumberFormat
                type="tel"
                format="+1 (###) ###-####"
                customInput={Input}
                placeholder="123 456-7890"
                id="phone"
                name="phone"
                required
            />

            <Label htmlFor="zipcode">zip code</Label>
            <Input type="number" id="zipCode" name="zipcode" placeholder="11101" />

            <Label htmlFor="message">How can we help you?</Label>
            <Input
                as="textarea"
                rows={5}
                id="message"
                name="message"
                placeholder="Write your message here"
            />

            <PrimaryBtn big="true" disabled={loading}>
                {loading ? 'Sending...' : 'Submit Contact Form'}
            </PrimaryBtn>
            <Thanks submit={submit} />
        </Form>
    );
});
