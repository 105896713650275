import { Article } from '@/Article';
import CLink from '@/CLink';
import { Input, Thanks } from '@/FormComponents';
import { SocialIcons } from '@/SocialIcons';
import { PrimaryBtn } from '@/buttons';
import CallBtn from '@/buttons/CallBtn';
import { ReactComponent as Pin } from '@a/icons/pin.svg';
import { ReactComponent as LogoText } from '@a/images/layout/logo-text.svg';
import logoVector from '@a/images/layout/logo-vector.svg';
import styled from '@emotion/styled';
import { useState } from 'react';
import { footerNavLinks, info } from './NavLinks';
import { flexCenter, flexSpace, flexStart } from './styles/classes';

const Section = styled.section`
    color: ${({ theme }) => theme.colors.gray3};
    background-color: ${({ theme }) => theme.colors.pink3};
`;

const Flex = styled.div`
    > div:first-of-type {
        max-width: 522px;
        width: 100%;

        > p:first-of-type {
            margin-top: 0;
        }
    }

    @media (min-width: 1024px) {
        ${flexSpace};
    }
`;

const Logo = styled(CLink)`
    ${flexCenter};
    flex-direction: column;
    margin-bottom: 64px;

    path {
        fill: ${({ theme }) => theme.colors.green2};
    }

    :hover,
    :focus {
        path {
            fill: ${({ theme }) => theme.colors.green1};
        }
    }

    > svg {
        margin-top: 28px;

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 120px;

        > svg {
            transform: scale(1.5);
            margin-top: 36.5px;
        }
    }
`;

const Address = styled(CLink)`
    ${flexStart};
    align-items: center;
    text-decoration: underline;
    max-width: max-content;
    color: ${({ theme }) => theme.colors.gray3};
    transition: color 0.3s ease-in-out;

    :hover,
    :focus {
        color: ${({ theme }) => theme.colors.green2} !important;

        path {
            fill: ${({ theme }) => theme.colors.green2};
        }
    }

    > svg {
        margin-right: 20px;
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${({ theme }) => theme.colors.gray3};
        }
    }

    :first-of-type {
        margin-bottom: 16px;
    }

    :nth-of-type(2) + a {
        margin: 24px 0 64px;
    }

    @media (min-width: 1024px) {
        :nth-of-type(2) + a {
            margin: 32px 0 80px;
        }
    }
`;

const Newsletter = styled.div`
    > h4 {
        margin: 0;
    }

    > p {
        margin: 8px 0 24px;
        color: ${({ theme }) => theme.colors.gray3};
    }

    > form {
        ${flexCenter};
        align-items: stretch;
        position: relative;

        > input {
            padding: 24px 16px;
            border: none;
            transition: box-shadow 0.3s ease-in-out;
            margin: 0;

            @media (pointer: fine) {
                :hover {
                    background-color: ${({ theme }) => theme.colors.gray1};
                }
            }

            :hover,
            :focus {
                box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.yellow1};
            }
        }
    }
`;

const Social = styled.div`
    ${flexStart};
    margin: 64px 0 32px;

    > span {
        margin-right: 32px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.gray2};
    }

    @media (min-width: 1024px) {
        margin-top: 0;
    }
`;

const Links = styled.div`
    margin-top: 32px;

    > p {
        margin: 0 0 24px;
        font-weight: 700;
    }

    > a {
        display: block;
        max-width: max-content;
        color: ${({ theme }) => theme.colors.pink1};
        text-transform: capitalize;

        :not(:last-of-type) {
            margin-bottom: 16px;
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.pink2} !important;
        }
    }
`;

export const Footer = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
    };

    return (
        <Section>
            <Article big>
                <Logo to="/">
                    <img src={logoVector} alt="beauty religion" width={68} height={60} />
                    <LogoText />
                </Logo>

                <Flex>
                    <div>
                        <p>West Hollywood</p>
                        <Address to={info.address.link}>
                            <Pin />
                            {info.address.text}
                        </Address>
                        <p>Dana Point</p>
                        <Address to={info.secondAddress.link}>
                            <Pin />
                            {info.secondAddress.text}
                        </Address>

                        <CallBtn />

                        <Newsletter>
                            <h4>Newsletter</h4>
                            <p>Join our mailing list. Never miss an update!</p>
                            <form onSubmit={handleSubmit}>
                                <Input
                                    type="email"
                                    id="newNewsletterSubscriber"
                                    name="newNewsletterSubscriber"
                                    required
                                    placeholder="Enter your email address"
                                />
                                <PrimaryBtn>Subscribe</PrimaryBtn>
                                <Thanks newsletter submit={submit} />
                            </form>
                        </Newsletter>
                    </div>
                    <div>
                        <Social>
                            <span>Follow us on:</span>
                            <SocialIcons pink />
                        </Social>

                        <a
                            href="https://www.bbb.org/us/ca/dana-point/profile/medical-spa/beauty-religion-inc-1126-1000131956/#sealclick"
                            target="_blank"
                            rel="nofollow noreferrer"
                        >
                            <img
                                src="https://seal-central-northern-western-arizona.bbb.org/seals/darkgray-seal-187-130-whitetxt-bbb-1000131956.png"
                                alt="Beauty Religion Inc BBB Business Review"
                            />
                        </a>

                        <Links>
                            <p>Navigation</p>

                            {footerNavLinks.map((link, i) => (
                                <CLink key={i} to={link.link}>
                                    {link.text}
                                </CLink>
                            ))}
                        </Links>
                    </div>
                </Flex>
            </Article>
        </Section>
    );
};
