import CLink from '@/CLink';
import { info } from '@/layout/NavLinks';
import phone from '@a/icons/phone.svg';
import styled from '@emotion/styled';
import PrimaryBtn from './PrimaryBtn';

const Btn = styled(PrimaryBtn)`
    > img {
        margin-right: 18px;
    }
`;

export default function CallBtn() {
    return (
        <Btn big="true" as={CLink} to={info.phone.link}>
            <img src={phone} alt="phone" /> Call Us - {info.phone.text}
        </Btn>
    );
}
